import React from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Form from './Form';
import PrivacyPolicy from './PrivacyPolicy';
import AdminUiRedirect from './AdminUiRedirect';

const Main = () => {

  return (
    <Router basename={window.contextPath}>
      <link rel="stylesheet" href="css/index.css" />
      <Header />
      {/* <Form/> */}
      <Switch>
        <Route path="/redirect/admin-ui" component={AdminUiRedirect} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/" component={Form} />
      </Switch>
      <Footer />
    </Router>

  );
}
export default Main;
