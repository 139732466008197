
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 1,
            otpSent: false,
            otpVerified: false,
            otpReceived: false,
            healthSystemOptions: [],
            formattedHelathSystems: [],
            selectedHealthSystem: null,
            newHealthSystem: "",
            checkboxChecked: false,
            newHealthSystemForm: false,
            onBoardingId: "",
            userName: "",
            errors: {},
            userEmail: "",
            userPassword: "",
            confirmPassword: "",
            otp: "",
            hospitalName: "",
            hospitalAddress: "",
            hospitalWebsite: "",
            hospitalDescription: "",
            healthSystemName: "",
            healthSystemAddress: "",
            healthSystemDescription: "",
            healthSystemWebsite: '',
            showPassword: false,
            showConfirmPassword: false,
            healthSystemJson: {},
            hospitalJson: {},
            onBoardingStatus: true,
            healthSystemId: ""
        };
    }

    componentDidMount() {
        this.showStep();
    }

    handleInputChange = (newValue) => {
        if (newValue !== "") {
            this.setState({ selectedHealthSystem: null });
            this.setState({ newHealthSystem: newValue });
        }
    };

    handleSelectChangeHealthSystem = (selectedHealthSystem) => {
        if (selectedHealthSystem) {
            this.state.selectedHealthSystem = selectedHealthSystem;
            this.setState(this.state);
        }
    };

    showStep = (stepNumber) => {
        if (stepNumber) {
            const steps = document.querySelectorAll('.step');

            steps.forEach(step => {
                step.style.display = 'none';
            });

            const currentStepElement = document.querySelector(`.step[data-step="${stepNumber}"]`);

            if (currentStepElement) {
                currentStepElement.style.display = 'block';
            } else {
                console.error(`Element with data-step="${stepNumber}" not found.`);
            }
        } else {
            stepNumber = 1;
            const currentStepElement = document.querySelector(`.step[data-step="${stepNumber}"]`);

            if (currentStepElement) {
                currentStepElement.style.display = 'block';
            } else {
                console.error(`Element with data-step="${stepNumber}" not found.`);
            }
        }
    }

    nextStep = (stepNumber) => {
        if (this.state.currentStep < 6) {
            this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }), () => {
                this.showStep(this.state.currentStep);
            });
        }
    }

    prevStep = (stepNumber) => {
        if (this.state.currentStep > 0) {
            if (this.state.otpVerified) {
                this.setState({
                    newHealthSystemForm: false,
                })
            }
            this.setState((prevState) => ({ currentStep: prevState.currentStep - 1 }), () => {
                this.showStep(this.state.currentStep);
            });
        }
    }

    getOTP = () => {
        var thisController = this;
        var emailpattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        if (thisController.state.userName.trim() === "") {
            alert("Full Name field cannot be empty. Please enter your name.");
        } else if (!thisController.state.userName.match(/^[A-Za-z '-]+$/)) {
            alert("Please provide a valid Name");
        } else if (!emailpattern.test(thisController.state.userEmail)) {
            alert("Please provide a valid Email Address");
        } else if (thisController.state.userEmail.trim() === "") {
            alert("Email field cannot be empty. Please enter your email address.");
        } else if (thisController.state.userPassword.trim() === "") {
            alert("Password field cannot be empty. Please enter password.");
        } else if (thisController.state.userPassword !== thisController.state.confirmPassword) {
            alert("Password and confirm password do not match. Please make sure your passwords match.");
        } else {
            var userOnboardingInfo = {
                userEmail: thisController.state.userEmail,
                name: thisController.state.userName,
                password: thisController.state.userPassword
            }
            $.ajax({
                //In this url we are doind both send otp and creating an onboard user
                url: "/my-jeeva-api/onboard/create",
                async: false,
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(userOnboardingInfo),

                success: function (res) {
                    if (res.success == true) {
                        if (res.result.status === "COMPLETE") {
                            thisController.redirectToAdmin();
                        } else {
                            thisController.state.onBoardingId = res.result.onboardingId;
                            thisController.state.healthSystemJson = res.result.healthSystem;
                            thisController.state.hospitalJson = res.result.hospital;
                            thisController.state.otpSent = true;
                            thisController.state.otpReceived = true;
                            thisController.setState(thisController.state);
                        }
                    } else {
                        console.log("Got no otp");
                    }
                },
            });
        }

    }

    redirectToAdmin = () => {
        this.props.history.push({
            pathname: "/redirect/admin-ui",
            state: {
                userEmail: this.state.userEmail,
                onBoardingStatus: true,
                userName: this.state.userName
            },
        });
    }

    verifyOTP = () => {
        var veriyOtpController = this;

        if (veriyOtpController.state.userName.trim() === "") {
            alert("Full Name field cannot be empty. Please enter your name.");
        } else if (!veriyOtpController.state.userName.match(/^[A-Za-z '-]+$/)) {
            alert("Please provide a valid Name");
        }
        else if (veriyOtpController.state.userEmail.trim() === "") {
            alert("Email field cannot be empty. Please enter your email address.");
        } else if (veriyOtpController.state.userPassword !== veriyOtpController.state.confirmPassword) {
            alert("Password and confirm password do not match. Please make sure your passwords match.");
        } else if(veriyOtpController.state.otp.trim() === "") {
            alert("OTP field cannot be empty. Please enter OTP.");
        } else {
            var otpVerificationModel = {
                emailId: veriyOtpController.state.userEmail,
                emailOtp: veriyOtpController.state.otp,
            }

            $.ajax({
                url: "/my-jeeva-api/onboard/verify-otp",
                async: false,
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(otpVerificationModel),

                success: function (res) {
                    if (res.success == true) {
                        if (res.result == true) {
                            veriyOtpController.state.otpVerified = true;
                            veriyOtpController.state.otpReceived = false;
                            veriyOtpController.setState(veriyOtpController.state);
                            veriyOtpController.getAllHealthSystems();
                            veriyOtpController.nextStep(1);
                        } else {
                            alert("The OTP you entered is incorrect. Please provide a valid OTP to proceed.")
                        }

                    } else {
                        console.log("Otp verification failed ");
                    }
                }
            });


        }

    }


    handleCheckboxClick = () => {
        const checkbox = document.getElementById('terms-conditions');
        if (checkbox.checked) {
            this.setState({ checkboxChecked: true })
        } else {
            this.setState({ checkboxChecked: false })
        }
    }

    handleHealthSystemCombo = () => {
        this.setState({ newHealthSystemForm: true , selectedHealthSystem : null})
    }

    handleUsername = (event) => {
        var name = event.target.value;
        this.state.userName = name;
        this.setState(this.state);
    }

    handleUserEmail = (event) => {
        var email = event.target.value.toLowerCase();
        if (this.state.userEmail !== email) {
            this.setState({ otpSent: false, otpReceived: false, otpVerified: false })
        }
        this.setState({ userEmail: email });
    }

    handleUserPassword = (event) => {
        var password = event.target.value;
        this.state.userPassword = password;
        this.setState(this.state);
    }
    handleUserConfirmPassword = (event) => {
        var confirmPassword = event.target.value;
        this.state.confirmPassword = confirmPassword;
        this.setState(this.state);
    }

    handleChangeOtp = (event) => {
        var otp = event.target.value;
        this.state.otp = otp;
        this.setState(this.state);
    }

    getAllHealthSystems = () => {
        var healthSysytemController = this;

        $.ajax({
            url: "/my-jeeva-api/onboard/health-systems",
            async: false,
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            success: function (res) {
                if (res.success == true) {
                    healthSysytemController.state.healthSystemOptions = res.result
                    healthSysytemController.setState(healthSysytemController.state);
                } else {
                    console.log("No Health System Found ");
                }
                healthSysytemController.state.formattedHelathSystems = healthSysytemController.state.healthSystemOptions.map(item => ({
                    label: item.name,
                    value: item.id,
                    description: item.description,
                    address: item.address,
                    website: item.website
                }));
            }
        })
    }

    updateUserHealthInfo = () => {
        var updateController = this;

        // For New Health System Form
        if (updateController.state.newHealthSystemForm) {

            var healthSystemName = updateController.state.healthSystemName.trim()
            var healthSystemDescription = updateController.state.healthSystemDescription.trim()
            var healthSystemWebsite = updateController.state.healthSystemWebsite.trim()
            var healthSystemAddress = updateController.state.healthSystemAddress.trim()

            var healthNamePattern = new RegExp(/^(?!^[^A-Za-z0-9]+$)[A-Za-z0-9\s!@#$%^&*()-_=+{}\[\]|\\;:'",<.>/?]+$/);
            var healthAddresPattern = new RegExp(/^([a-zA-Z0-9 /''()#.,-])*$/);
            var healthWebsitePattern = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/);

            if (healthSystemName === "" || healthSystemWebsite === "" || healthSystemAddress === "") {
                alert("Fields cannot be empty, Please fill all the fields before continuing.")
            } else if (!healthNamePattern.test(healthSystemName)) {
                alert("Please provide valid Health System Name")
            } else if (!healthAddresPattern.test(healthSystemAddress)) {
                alert("Please provide valid Health System Address")
            } else if (!healthWebsitePattern.test(healthSystemWebsite)) {
                alert("Please provide valid Health System Website")
            } else {
                var updateOnboardingInfo = {
                    userEmail: updateController.state.userEmail,
                    name: updateController.state.userName,
                    onboardingId: updateController.state.onBoardingId,
                    healthSystem: {
                        name: healthSystemName,
                        description: healthSystemDescription,
                        website: healthSystemWebsite,
                        address: healthSystemAddress
                    }
                }
                $.ajax({
                    url: "/my-jeeva-api/onboard/update",
                    async: false,
                    method: "PATCH",
                    headers: {
                        'Content-Type': "application/json"
                    },
                    data: JSON.stringify(updateOnboardingInfo),

                    success: function (res) {
                        if (res.success == true) {
                            var healthSystemJson = JSON.parse(res.result.healthSystemJson);
                            updateController.state.healthSystemJson = JSON.parse(res.result.healthSystemJson);;
                            updateController.state.healthSystemId = healthSystemJson.id;
                            updateController.setState(updateController.state);
                            updateController.nextStep(2);
                        } else {
                            console.log("Update not successfull");
                        }
                    },
                });
            }

        } else {

            // For Existed Health System
            var selectedHealthSystem = updateController.state.selectedHealthSystem

            if (selectedHealthSystem === null) {
                alert("Fields cannot be empty. Please fill all the fields.")
            } else {
                var updateOnboardingInfo = {
                    userEmail: updateController.state.userEmail,
                    name: updateController.state.userName,
                    onboardingId: updateController.state.onBoardingId,
                    healthSystem: {
                        id: updateController.state.selectedHealthSystem.value,
                        name: updateController.state.selectedHealthSystem.label,
                        description: updateController.state.selectedHealthSystem.description,
                        website: updateController.state.selectedHealthSystem.website,
                        address: updateController.state.selectedHealthSystem.address
                    }
                }
                $.ajax({
                    url: "/my-jeeva-api/onboard/update",
                    async: false,
                    method: "PATCH",
                    headers: {
                        'Content-Type': "application/json"
                    },
                    data: JSON.stringify(updateOnboardingInfo),

                    success: function (res) {
                        if (res.success == true) {
                            var healthSystemJson = JSON.parse(res.result.healthSystemJson);
                            updateController.state.healthSystemJson = JSON.parse(res.result.healthSystemJson);
                            updateController.state.healthSystemId = healthSystemJson.id;
                            updateController.setState(updateController.state);
                            updateController.nextStep(2);
                        } else {
                            console.log("Update not successfull");
                        }
                    },
                });
            }
        }

    }

    updateUserHospitalInfo = () => {
        var updateController = this;
        var hospitalName = updateController.state.hospitalName.trim()
        var hospitalDescription = updateController.state.hospitalDescription.trim()
        var hospitalWebsite = updateController.state.hospitalWebsite.trim()
        var hospitalAddress = updateController.state.hospitalAddress.trim()

        var hospitalNamePattern = new RegExp(/^(?!^[^A-Za-z0-9]+$)[A-Za-z0-9\s!@#$%^&*()-_=+{}\[\]|\\;:'",<.>/?]+$/);
        var hospitalNameAddresPattern = new RegExp(/^([a-zA-Z0-9 /''()#.,-])*$/);
        var hospitalNameWebsitePattern = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/);

        if (hospitalName === "" || hospitalWebsite === "" || hospitalAddress === ""
        ) {
            alert("Fields cannot be empty. Please fill all the fields.")
        } else if (!hospitalNamePattern.test(hospitalName)) {
            alert("Please provide valid Hospital Name")
        } else if (!hospitalNameAddresPattern.test(hospitalAddress)) {
            alert("Please provide valid Hospital Address")
        } else if (!hospitalNameWebsitePattern.test(hospitalWebsite)) {
            alert("Please provide valid Hospital Website")
        } else {
            var updateOnboardingInfo = {
                userEmail: updateController.state.userEmail,
                name: updateController.state.userName,
                onboardingId: updateController.state.onBoardingId,
                hospital: {
                    healthSystemId: updateController.state.healthSystemId,
                    name: hospitalName,
                    description: hospitalDescription,
                    website: hospitalWebsite,
                    address: hospitalAddress
                },
                healthSystem : {
                    id : updateController.state.healthSystemJson.id,
                    name : updateController.state.healthSystemJson.name,
                    description : updateController.state.healthSystemJson.description,
                    website :  updateController.state.healthSystemJson.website,
                    address : updateController.state.healthSystemJson.address
                }
               
            }
            $.ajax({
                url: "/my-jeeva-api/onboard/update",
                async: false,
                method: "PATCH",
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(updateOnboardingInfo),

                success: function (res) {
                    if (res.success == true) {
                        updateController.nextStep(2);
                    } else {
                        console.log("Update not successfull");
                    }
                },
            });
        }
    }

    submitOnboardingUser = () => {
        var sumbitController = this;
        if (sumbitController.state.checkboxChecked) {
            $.ajax({
                url: "/my-jeeva-api/onboard/submit-details",
                method: "POST",
                data: {
                    userOnboardingId: sumbitController.state.onBoardingId
                },
                success: function (res) {
                    if (res.success == true) {
                        if (res.result === "success") {
                            sumbitController.redirectToAdminUi();
                        }
                    } else {
                        alert("We regret to inform you that the form submission has failed.")
                    }
                }, error: function (xhr) {
                    if (xhr.status == 401) {
                        window.location.href = "";
                    }
                }
            })
            sumbitController.redirectToAdminUi();
        } else {
            console.log("Check box not checked so form is not submitted");
        }

    }

    redirectToAdminUi = () => {
        this.props.history.push('/redirect/admin-ui');
    };


    handleHospitalName = (event) => {
        var hospitalName = event.target.value;
        this.state.hospitalName = hospitalName;
        this.setState(this.state);
    }

    handleHospitalDescription = (event) => {
        var hospitalDescription = event.target.value;
        this.state.hospitalDescription = hospitalDescription;
        this.setState(this.state);
    }
    handleHospitalAddress = (event) => {
        var hospitalAddress = event.target.value
        this.state.hospitalAddress = hospitalAddress;
        this.setState(this.state);
    }
    handleHospitalWebsite = (event) => {
        var hospitalWebsite = event.target.value;
        this.state.hospitalWebsite = hospitalWebsite;
        this.setState(this.state);
    }
    handleHealthSystemName = (event) => {
        var healthSystemName = event.target.value;
        this.state.healthSystemName = healthSystemName;
        this.setState(this.state);
    }

    handleHealthSystemDescription = (event) => {
        var healthSystemDescription = event.target.value;
        this.state.healthSystemDescription = healthSystemDescription;
        this.setState(this.state);
    }
    handleHealthSystemAddress = (event) => {
        var healthSystemAddress = event.target.value;
        this.state.healthSystemAddress = healthSystemAddress;
        this.setState(this.state);
    }
    handleHealthSystemWebsite = (event) => {
        var healthSystemWebsite = event.target.value;
        this.state.healthSystemWebsite = healthSystemWebsite;
        this.setState(this.state);
    }
   
    validatePwd = () => {
        let errors = {};
        let formIsValid = true;

        if (!document.getElementById("password").value) {
            formIsValid = false;
            errors["password"] = "*Please enter the password to confirm";
        } else if (document.getElementById("password").value != "") {
            var password = document.getElementById("password").value;

            if (password.length < 8) {
                formIsValid = false;
                errors["password"] =
                    "*Please enter Minimum 8 Characters.";
            }
            else if (
                !document
                    .getElementById("password")
                    .value.match(
                        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!#@$%^&(){}[\]:;<>,.?/~_+\-=[\]|]).{8,30}$/
                    )
            ) {
                formIsValid = false;
                errors["password"] =
                    "*Please enter Minimum 8 Characters, atleast 1 number, 1 capital letter, 1 small letter and 1 special character .";
            }
        } else if (
            document.getElementById("password").value !==
            document.getElementById("confirmPassword").value
        ) {
            formIsValid = false;
            errors["password"] = "*Passwords don't match";
        }

        this.setState({
            errors: errors,
        });
        return formIsValid;
    };

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    togglePasswordVisibilityForConfirm = () => {
        this.setState((prevState) => ({
            showConfirmPassword: !prevState.showConfirmPassword,
        }));
    };

    customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'black' : 'black', // Set the text color based on selection
            fontWeight: state.isSelected ? 'bold' : 'normal', // Set the font weight based on selection
        }),
    };


    render() {
        const { hospitalJson, healthSystemJson } = this.state;
        return (
            <div className="container">
                <form id="multiStepForm" className="step-form">

                    {/* <!--Personal Details--> */}
                    <div className="form-container step" data-step="1" style={{ display: "block" }}>
                        <div className="current-form">
                            <div className="left-column">
                                <div className="form-title"><span>01 </span>Personal Details</div>
                                <img alt="" src="images\personal-details.png" />
                                {this.state.otpReceived &&
                                    <div className="form-btn desktop-btn">
                                        <button type="button" onClick={this.verifyOTP}>verify OTP</button>
                                    </div>
                                }
                                {!this.state.otpSent &&
                                    <div className="form-btn desktop-btn">
                                        <button type="button" onClick={this.getOTP}>Get OTP</button>
                                    </div>

                                }
                                {this.state.otpVerified &&
                                    <div className="form-btn desktop-btn">
                                        <button type="button" onClick={() => this.nextStep(1)}>Next</button>
                                    </div>

                                }

                            </div>
                            <div className="right-column">

                                <div className="full-width-fields">

                                    <div>
                                        <label htmlFor="fname">Full Name</label>
                                        <input type="text"
                                            onChange={this.handleUsername}
                                            id="fname"
                                            name="fname"
                                            placeholder="Enter your Full Name"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email">Email Address</label>
                                        <input onChange={this.handleUserEmail}
                                            type="email" id="email"
                                            placeholder="Enter your Email Address"
                                        />
                                    </div>
                                    <div>
                                        <span style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            flexDirection: "row-reverse",
                                            marginLeft: "-10px"
                                        }}>
                                            <label htmlFor="password">Password</label>
                                            <input
                                                onChange={this.handleUserPassword}
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                id="password"
                                                placeholder="Enter your Password"
                                                onBlur={this.validatePwd}
                                            />
                                            <FontAwesomeIcon
                                                icon={this.state.showPassword ? faEyeSlash : faEye}
                                                onClick={this.togglePasswordVisibility}
                                                style={{ cursor: 'pointer', marginTop: "-40px" }}
                                            />
                                        </span>
                                    </div>
                                    {this.state.errors.password &&
                                        <div className="errorMsg"
                                            style={{
                                                color: "red",
                                                display: "flex",
                                                fontSize: "small",
                                                marginTop: "-20px",
                                                backgroundColor: "white"
                                            }}>
                                            {this.state.errors.password}
                                        </div>}

                                    <div>
                                        <span style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            flexDirection: "row-reverse",
                                            marginLeft: "-10px"
                                        }}>
                                            <label htmlFor="password">Confirm Password</label>
                                            <input
                                                onChange={this.handleUserConfirmPassword}
                                                type={this.state.showConfirmPassword ? 'text' : 'password'}
                                                id="confirmpassword"
                                                name="confirmpassword"
                                                placeholder="Confirm your Password"
                                            />
                                            <FontAwesomeIcon
                                                icon={this.state.showConfirmPassword ? faEyeSlash : faEye}
                                                onClick={this.togglePasswordVisibilityForConfirm}
                                                style={{ cursor: 'pointer', marginTop: "-40px" }}
                                            />
                                        </span>
                                    </div>

                                    {this.state.otpSent &&
                                        <div>
                                            <label htmlFor="otp">OTP</label>
                                            <input type="text" onChange={this.handleChangeOtp} id="otp" name="otp" pattern="[0-9]{6}"
                                                placeholder="Please enter a 6-digit OTP sent to your Email Address " required />
                                        </div>

                                    }

                                </div>

                                {this.state.otpReceived &&
                                    <div className="form-btn mobile-btn">
                                        <button type="button" onClick={this.verifyOTP}>verify OTP</button>
                                    </div>
                                }
                                {!this.state.otpSent &&
                                    <div className="form-btn mobile-btn">
                                        <button type="button" onClick={this.getOTP}>Get OTP</button>
                                    </div>

                                }
                                {this.state.otpVerified &&
                                    <div className="form-btn mobile-btn">
                                        <button type="button" onClick={() => this.nextStep(1)}>Next</button>
                                    </div>

                                }

                            </div>
                        </div>
                        <div className="other-forms">
                            <div>02 Health Systems</div>
                            <div>03 Hospital</div>

                        </div>
                    </div>

                    {/* <!--Health Systems and Hospital--> */}
                    <div className="form-container step" data-step="2" >
                        <div className="other-forms other-previous-forms">
                            <div>01 Personal Details</div>
                        </div>
                        <div className="current-form">
                            <div className="left-column">
                                <div className="form-title"><span>02 </span>Health Systems</div>
                                <img alt='' src="images\hospital.png" />
                                <div className="form-btn desktop-btn">
                                    <button type="button" onClick={() => this.prevStep(1)} className="prev-btn">Prev</button> <button
                                        type="button" onClick={this.updateUserHealthInfo}>Next</button>
                                </div>
                            </div>
                            <div className="right-column" style={{ width: "55% !important" }}>
                                {!this.state.newHealthSystemForm &&
                                    <div className=" healthSystem-hospital">
                                        <div>
                                            <label htmlFor="health-system">Health System aka corporation</label>

                                            <Select
                                                id="health-system"
                                                value={this.state.selectedHealthSystem}
                                                onChange={this.handleSelectChangeHealthSystem}
                                                options={this.state.formattedHelathSystems}
                                                isSearchable={true}
                                                placeholder="Select Health System"
                                                styles={this.customStyles}
                                                formatOptionLabel={(option) => (
                                                    <div>
                                                        <p>{option.label}</p>
                                                    </div>
                                                )}
                                            />
                                            <div className="add-btn">
                                                <button onClick={this.handleHealthSystemCombo}
                                                    title='Add New Health System' >
                                                    <img
                                                        alt=''
                                                        style={{ height: "35px" }}
                                                        src="images/add-white.png" /></button>
                                            </div>
                                        </div>
                                    </div>

                                }

                                {this.state.newHealthSystemForm &&
                                    <div className='full-width-fields'>
                                        <div>
                                            <label htmlFor="healthSystem">Health System </label>
                                            <input onChange={this.handleHealthSystemName}
                                                type="text" id="healthsystem"
                                                name="healthSystem"
                                                // value={healthSystemJson.website || this.state.healthSystemName}
                                                placeholder="Enter your Health System"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="healthSystemdescription">Description</label>
                                            <textarea
                                                onChange={this.handleHealthSystemDescription}
                                                id="healthsystemdescription"
                                                placeholder="Enter your Health System Description"
                                                wrap="soft"
                                            >
                                            </textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="healthSystemaddress">Address</label>
                                            <input onChange={this.handleHealthSystemAddress}
                                                type="text" id="healthsystemaddress"
                                                name="healthSystemaddress"
                                                // value={healthSystemJson.address || this.state.healthSystemAddress}
                                                placeholder="Enter your Health System Address"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="healthSystemwebsite">Website</label>
                                            <input onChange={this.handleHealthSystemWebsite}
                                                type="text" id="healthsystemwebsite"
                                                name="healthSystemwebsite"
                                                // value={healthSystemJson.website || this.state.healthSystemWebsite}
                                                placeholder="Enter your Health System Website"

                                            />
                                        </div>
                                    </div>
                                }

                                <div className="form-btn mobile-btn" style={{marginTop:"15px"}}>
                                    <button type="button" onClick={() => this.prevStep(1)} className="prev-btn">Prev</button> <button
                                        type="button" onClick={this.updateUserHealthInfo}>Next</button>
                                </div>
                            </div>
                        </div>

                        <div className="other-forms">
                            <div>03 Hospital</div>
                        </div>

                    </div>

                    <div className='form-container step' data-step="3">
                        <div className="other-forms other-previous-forms">
                            <div>01 Personal Details</div>
                            <div>02 Health Systems </div>
                        </div>

                        <div className="current-form">
                            <div className="left-column">
                                <div className="form-title"><span>03 </span>Hospital</div>
                                <img alt='' style={{ width: "200px" }} src="images\hospital.png" />
                                <div className="form-btn desktop-btn">
                                    <button type="button" onClick={() => this.prevStep(2)} className="prev-btn">Prev</button> <button
                                        type="button" onClick={this.updateUserHospitalInfo}>Preview</button>
                                </div>
                            </div>
                            <div className="right-column">
                                <div className="full-width-fields">
                                    <div>
                                        <label htmlFor="hospital">Hospital </label>
                                        <input onChange={this.handleHospitalName}
                                            type="text" id="hospital"
                                            name="hospital"
                                            placeholder="Enter your Hospital Name"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="hospitaldescription">Description</label>
                                        <textarea name="hospitaldescription"
                                            onChange={this.handleHospitalDescription}
                                            placeholder="Enter your Hospital Description"
                                            id="hospitaldescription"
                                            wrap="soft"
                                        >
                                        </textarea>
                                    </div>

                                    <div>
                                        <label htmlFor="hospitaladdress">Address</label>
                                        <input onChange={this.handleHospitalAddress}
                                            type="text" id="hospitaladdress"
                                            name="hospitaladdress"
                                            placeholder="Enter your Hospital Address"

                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="hospitalwebsite">Website</label>
                                        <input onChange={this.handleHospitalWebsite}
                                            type="text" id="hospitalwebsite"
                                            name="hospitalwebsite"
                                            placeholder="Enter your Hospital Website"

                                        />
                                    </div>

                                </div>
                                <div className="form-btn mobile-btn">
                                    <button type="button" onClick={() => this.prevStep(2)} className="prev-btn">Prev</button> <button
                                        type="button" onClick={this.updateUserHospitalInfo}>Preview</button>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* <!--Form Preview--> */}
                    <div className="form-container step" data-step="4">

                        {/* <!--Personal Details Preview--> */}
                        <div className="current-form preview-form">
                            <div className="left-column">
                                <div className="form-title"><span>01 </span>Personal Details</div>
                            </div>
                            <div className="right-column">
                                <div className="half-width-fields">
                                </div>
                                <div className="full-width-fields">
                                    <div>
                                        <label htmlFor="fname">Full Name</label>
                                        <div id="preview-fname" className="preview-fields">
                                            {this.state.userName}
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="email">Email Address</label>
                                        <div id="preview-email" className="preview-fields">
                                            {this.state.userEmail}
                                        </div>
                                    </div>

                                    {/* <div>
                                        <label htmlFor="phone">Phone Number</label>
                                        <div id="preview-phone" className="preview-fields"></div>
                                    </div> */}

                                </div>
                            </div>
                        </div>

                        <div className="section-bracker"></div>

                        {/* <!--Health System Preview--> */}
                        <div className="current-form preview-form">
                            <div className="left-column">
                                <div className="form-title"><span>02 </span>Health System</div>
                            </div>
                            <div className="right-column">
                                {!this.state.newHealthSystemForm &&
                                    <div className="full-width-fields">
                                        <div>
                                            <label htmlFor="health-system">Health System aka corporation</label>
                                            <div id="preview-health-system" className="preview-fields">
                                                {this.state.selectedHealthSystem && (this.state.selectedHealthSystem.label)}
                                            </div>
                                        </div>
                                        
                                    </div>
                                }

                                {this.state.newHealthSystemForm &&

                                    <div className="full-width-fields">
                                        <div>
                                            <label htmlFor="healthSystem">Health System </label>
                                            <div id="preview-healthSystem" className="preview-fields">
                                                {this.state.healthSystemName}
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="healthSystemdescription">Health System Description</label>
                                            {/* <div id="preview-healthSystemdescription" className="preview-fields">
                                                {this.state.healthSystemDescription}
                                            </div> */}
                                            <textarea readOnly className='preview-fields'
                                                value={this.state.healthSystemDescription}
                                            >
                                            </textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="healthSystemaddress">Health System Address</label>
                                            <div id="preview-healthSystemaddress" className="preview-fields">
                                                {this.state.healthSystemAddress}
                                            </div>

                                        </div>
                                        <div>
                                            <label htmlFor="healthSystemwebsite">Health System Website</label>
                                            <div id="preview-healthSystemwebsite" className="preview-fields">
                                                {this.state.healthSystemWebsite}
                                            </div>
                                        </div>
   
                                    </div>
                                }

                            </div>
                        </div>
                        <div className="section-bracker"></div>

                         {/* <!--Hospital Preview--> */}
                         <div className="current-form preview-form">
                            <div className="left-column">
                                <div className="form-title"><span>03 </span>Hospital</div>
                            </div>
                            <div className="right-column">
        
                                    <div className="full-width-fields">
                                        <div>
                                            <label htmlFor="hospital">Hospital </label>
                                            <div id="preview-hospital" className="preview-fields">
                                                {this.state.hospitalName}
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="hospitaldescription">Hospital Description</label>
                                            <textarea readOnly className='preview-fields'
                                                value={this.state.hospitalDescription}
                                            >
                                            </textarea>
                                        </div>

                                        <div>
                                            <label htmlFor="hospitaladdress">Hospital Address</label>
                                            <div id="preview-hospitaladdress" className="preview-fields">
                                                {this.state.hospitalAddress}
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="hospitalwebsite">Hospital Website</label>
                                            <div id="preview-hospitalwebsite" className="preview-fields">
                                                {this.state.hospitalWebsite}
                                            </div>
                                        </div>

                                    </div>

                            </div>
                        </div>


                        <div className="section-bracker"></div>

                        <span style={{ margin: "10px" }}>
                            <span className="edit-btn">
                                <button type="button" onClick={() => this.prevStep(2)} >Edit</button>
                            </span>
                            <input
                                style={{ marginLeft: "15px" }}
                                type="checkbox"
                                id="terms-conditions"
                                name="terms-conditions"
                                required
                                onChange={this.handleCheckboxClick}
                            />
                            <Link style={{ marginLeft: "10px" }} to='/privacy-policy' target="_blank" >Terms and Conditions</Link>
                        </span>
                        <div className="form-btn full-width-btn" style={{ justifyContent: 'center' }}>

                            <button onClick={this.submitOnboardingUser} type="submit">Submit</button>

                        </div>
                    </div>

                </form>
            </div>
        );
    }
}

export default Form;
