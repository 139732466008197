import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class PrivacyPolicy extends Component {

  render() {
    return (
      <div>

        <div className="main-content " id="privacyPolicyContainer" style={{ width: "94vw" }}>
          <h2>MyJEEVA Terms of Service</h2>
          <h2>Last Updated: July 31, 2023</h2>

          <h3>1. DESCRIPTION OF SERVICES AND LIMITED LICENSE TO USE SERVICES</h3>
          <p>
            These Terms of Use ("Terms") constitute an agreement between Jeeva Care, Inc., its
            affiliates, including MyJEEVA ("JEEVA" or "Jeeva Care" or "we"), and users ("you" or
            "User"). They govern the use of MyJEEVA’s online and mobile applications, including
            <a href='https://www.myjeeva.net' target="_blank" rel="noopener noreferrer"> www.myjeeva.net </a> and the MyJEEVA mobile app, collectively referred to as "Services."
            Please carefully review these Terms, our Privacy Policy, and other applicable terms
            based on the Service used. Your use of any Service indicates acceptance of these
            Terms.
          </p>

          <p>
            Jeeva Care, Inc.'s Privacy Policy, integral to these Terms, is accessible at
            <a href='https://www.myjeeva.net/privacypolicy' target="_blank" rel="noopener noreferrer"> www.myjeeva.net/privacypolicy </a>Your agreement to these Terms includes acceptance of
            the Privacy Policy.
          </p>

          <p>
            As a User, you affirm acceptance of these Terms, the Privacy Policy, and specific terms
            for respective Services. Failure to agree necessitates discontinuation of Service use.
            JEEVA CARE, INC retains the right to modify these Terms, with the "Last Updated"
            date indicating changes. Continued use post-modification implies acceptance.
            Bookmark this page for updates.
          </p>

          <p>
            Services are for individual, clinic, hospital, or business use only. Except as permitted by
            these Terms, selling or exchanging derived content from Services is prohibited. By
            using Services, you are granted a personal, non-exclusive, non-transferable license,
            subject to compliance with these Terms.
          </p>
          <br />
          <h3>2. DEFINITION OF OUR SERVICES</h3>
          <p>
            Jeeva Care, Inc. provides the following Services:
          </p>
          <p>
            <b>Providers:</b> Groups offering services to patients, including clinicians, service providers,
            home healthcare, etc.
          </p>
          <p>
            <b>Connecting Providers with Patients and Caregivers:</b> Facilitates communication,
            messaging, and alerts.
          </p>
          <p>
            <b>Privacy and Security Principles:</b> Emphasizes strong privacy and security practices.
          </p>
          <p>
            <b>Confidential Data Storage:</b> Ensures confidentiality of stored personal data.
          </p>
          <p>
            <b>Global Access:</b> Utilizes global infrastructure for service accessibility.
          </p>
          <p>
            <b>Use of Information to Improve Services:</b> Analyzes user interactions to enhance
            Services.
          </p>
          <p>
            <b>No Advertisement:</b> Ad-free communication channels in MyJEEVA.
          </p>
          <p>
            <b>Communicating with Businesses:</b> Enables business interactions via MyJEEVA.
          </p>
          <p>
            <b>No Access to Emergency Services:</b> Does not connect to emergency services; users
            must contact relevant providers directly.
          </p>
          <br />

          <h3>3. USE OF OUR SERVICES</h3>

          <p>
            <b>Registration:</b>  Requires accurate information; consent to receive registration codes.
          </p>
          <p>
            <b>Age:</b>  Users must be at least 13 years old; parental consent if underage.
          </p>
          <p>
            <b>Devices and Software:</b>  Users provide necessary devices and consent to updates.
          </p>
          <p>
            <b>Fees and Taxes:</b>  Users bear responsibility for associated costs.
          </p>
          <p>
            <b>Legal and Acceptable Use:</b>  Users agree to lawful and acceptable use.
          </p>
          <p>
            <b>Harm to Jeeva Care:</b>  Users must not disrupt Services or harm Jeeva Care, Inc.
          </p>
          <p>
            <b>Keeping Your Account Secure:</b>  Users are responsible for account security; must report unauthorized use.
          </p>
          <br />

          <h3>4. INTELLECTUAL PROPERTY RIGHTS</h3>
          <p>
            <b>Your Rights:</b> Users retain ownership of submitted information.
          </p>
          <p>
            <b>Jeeva Care, Inc.'s Rights:</b> Owns intellectual property associated with Services.
          </p>
          <p>
            <b>Your License to MyJEEVA:</b> Grants a license for Service operation and provision.
          </p>
          <p>
            <b>Jeeva Care, Inc.'s License to You:</b> Grants a limited license for Service use.
          </p>
          <p>
            <b>Reporting Third-Party Infringement:</b> Users report intellectual property infringement; Jeeva Care, Inc. may take action.
          </p>
          <br />

          <h3>5. LIABILITIES</h3>
          <p>
            <b>DISCLAIMER:</b> Services provided "as is"; JEEVA CARE, INC not liable for disruptions, delays, or imperfections. Users use Services at their own risk. No warranties, express or implied.
          </p>
          <p>
            <b>LIMITATION OF LIABILITY:</b> Liability limited to foreseeable consequences of breach, excluding death, personal injury, or fraudulent misrepresentation. Aggregate liability will not exceed the past twelve months' user payment.
          </p>
          <br />

          <h3>6. DISPUTE RESOLUTION</h3>
          <p>
            Users agree to resolve Disputes exclusively in the United States District Court for the Eastern District of Texas or a state court in Brazoria County, Texas.
          </p>
          <p>
            <b>Special Arbitration Provision for United States Or Canada Users:</b> Users in the U.S. or Canada agree to submit Disputes to binding individual arbitration, excluding intellectual property disputes and small claims.
          </p>
          <br />

          <h3>7. AVAILABILITY AND TERMINATION OF OUR SERVICES</h3>
          <p>
            <b>Availability of Our Services:</b> JEEVA CARE, INC may modify or discontinue Services with notice. Interruptions may occur for maintenance, repairs, upgrades, or unforeseen events.
          </p>
          <p>
            <b>Termination:</b> Users can terminate by deleting accounts. JEEVA CARE, INC may terminate for suspicious or unlawful conduct.
          </p>
          <br />

          <h3>8. OTHER</h3>
          <p>
            Unless mutually agreed, these Terms constitute the entire agreement, superseding prior agreements.
          </p>
          <p>
            JEEVA CARE, INC may designate specific Services under separate terms.
          </p>
          <p>
            Services not intended for distribution where prohibited by law.
          </p>
          <p>
            Users comply with applicable export control and trade sanctions laws.
          </p>
          <p>
            JEEVA CARE, INC reserves the right to remove inappropriate content.
          </p>
          <p>
            JEEVA CARE, INC is apolitical and nondiscriminatory.
          </p>
          <p>
            Amendments to Terms will be communicated with a 30-day notice.
          </p>
          <p>
            Rights and obligations under Terms freely assignable by JEEVA CARE, INC.
          </p>
          <p>
            Users may not transfer rights or obligations without prior written consent.
          </p>
          <p>
            Our failure to enforce Terms doesn't waive our rights.
          </p>
          <p>
            Invalid provisions won't affect the validity of remaining Terms.
          </p>
          <p>
            Our Terms do not limit consumer legal or data subject rights.
          </p>
          <p>
            Feedback is appreciated but not obligatory; may be used without compensation.
          </p>
          <br />
          <br />
          <br />


          <h2>PRIVACY POLICY</h2>
          <h2>Last Updated: January 31, 2020</h2>
          <p>
            This Privacy Policy is designed to inform users of Jeeva Care, Inc. ("Jeeva Care, Inc.") websites and mobile applications (collectively, the "Service") about how we gather and use personal information collected in connection with your use of the Services. This Privacy Policy applies to all our Services unless specified otherwise. Please read the Terms of Service ("Terms"), which describes the terms under which you use our Services.
          </p>

          <h2>Your Personal Information</h2>
          <p>
            Your Personal Information includes information that individually identifies you or is information about you that can be traced back to you, your IP address, or your location. It may include, but is not limited to your name, address, employer, email address, phone number, and other information about you. If you do not agree with this Privacy Policy, you must not accept these terms and may not use the Site or Service.
          </p>

          <h2>Information Collection</h2>
          <p><b>Information You Provide:</b></p>
          <ul>
            <li><b>Your Account Information:</b> You provide your email address and mobile phone number, and other basic information to create a MyJEEVA account. This may include additional information in your profile, such as a profile picture, your birthday, your location, and about information.</li>
            <li><b>Your Contacts:</b> To help you communicate with others, we may help you identify your contacts who also use MyJEEVA. You can create, join, or get added to groups and broadcast lists, and such groups and lists get associated with your account information.</li>
            <li><b>Customer Support:</b> You may provide us with information related to your use of our Services, including copies of your messages, and how to contact you so we can provide you customer support.</li>
          </ul>

          <p><b>Information We Collect:</b></p>
          <ul>
            <li><b>Usage and Log Information:</b> We collect information about your activity on our Services, including service-related, diagnostic, and performance information.</li>
            <li><b>Device and Connection Information:</b> We collect device and connection-specific information when you install, access, or use our Services.</li>
            <li><b>Location Information:</b> We collect device location information if you use our location features.</li>
            <li><b>Cookies:</b> We use cookies to provide MyJEEVA for web and desktop and other web-based services.</li>
            <li><b>Businesses on MyJEEVA:</b> Businesses you interact with using MyJEEVA provide us information about their interactions with you.</li>
            <li><b>Third-Party Service Providers:</b> We work with third-party service providers to help us operate, provide, improve, understand, customize, support, and market our Services.</li>
          </ul>

          <h2>How We Use Information</h2>
          <p>
            We use the information we have to operate, provide, improve, understand, customize, support, and market our Services.
          </p>
          <ul>
            <li><b>Our Services:</b> We use the information to operate and provide our Services, including providing customer support, and improving, fixing, and customizing our Services.</li>
            <li><b>Safety and Security within MyJEEVA:</b> We use the information to verify and investigate suspicious activity or violations of our Terms, and to ensure our Services are being used legally.</li>
            <li><b>No Advertisements:</b> We do not allow third-party advertisements on MyJEEVA.</li>
            <li><b>Security Measure:</b> We have taken reasonable and necessary steps to ensure that all Personal Information collected will remain secure by applying end-to-end encryption.</li>
          </ul>

          <h2>Accessing, Changing, and Deleting Your Information</h2>
          <p>
            You may request access, changes, or deletions to Personal Information and request information about our collection, use, and disclosure of such information by using Service Settings, changing your profile, or deleting your MyJEEVA account.
          </p>

          <h2>Clinical Trial – Patient Consent Form</h2>
          <p>
            We strongly recommend conducting a clinical trial for a minimum of 90 days with your treatments to understand the patient engagement with the platform. MyJEEVA is an open platform where you can make changes as desired and optimize your content for your patients and caregivers. Please download the patient consent form at <a href="https://www.myjeeva.net/patientconsentform" target="_blank" rel="noopener noreferrer">www.myjeeva.net/patientconsentform</a>.
          </p>

          <h2>How the General Data Protection Regulation Applies to Our European Region Users</h2>
          <p>
            We collect, use, and share the information we have as described above. How you exercise your rights: Under the General Data Protection Regulation or other applicable local laws, you have the right to access, rectify, port, and erase your information.
          </p>

          <h2>Updates to Our Policy</h2>
          <p>
            We will notify you before we make changes to this Privacy Policy and give you the opportunity to review the revised Privacy Policy before you choose to continue using our Services.
          </p>

          <h2>Contact Information</h2>
          <p>
            For any queries, please contact us at <a href="mailto:support@myjeeva.net" target="_blank" rel="noopener noreferrer">support@myjeeva.net</a>.
          </p>

          <br />
        </div>

      </div>
    );
  }
}
export default withRouter(PrivacyPolicy);