import React from 'react';
import { createRoot } from 'react-dom/client';
import Main from './Main';
import { BrowserRouter } from 'react-router-dom';

const renderReactDom = () => {
  window.rootContent = document.getElementById('root');

  // Use createRoot to render your app
  const rootElement = createRoot(window.rootContent);
  rootElement.render(
    <BrowserRouter basename={window.contextPath}>
      <Main />
    </BrowserRouter>
  );
};

renderReactDom();
