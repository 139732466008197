
import React, { Component } from 'react';

class Header extends Component {
  constructor(props) {
    super(props);

    // Initialize state if needed
    this.state = {
      message: 'Hello, React!'
    };
  }

  render() {
    return (
        <header className="header">
              <link rel="stylesheet" href="css/index.css" />
        <div className="header-container">
            <nav>
                <div className="logo">
                    <a href="https://myjeeva.net/"><img src="images/logo.svg"/></a>
                </div>
                <input type="checkbox" id="menu-toggle"/>
                <label htmlFor="menu-toggle" className="menu-icon">&#9776;</label>
                <ul className="menu">
                    <li><a href="https://myjeeva.net/#welcome">About MyJEEVA</a></li>
                    <li><a href="https://myjeeva.net/#why-my-jeeva">Why MyJEEVA</a></li>
                    <li><a href="https://myjeeva.net/#patient-stories">Patient Stories</a></li>
                    <li><a href="https://myjeeva.net/#we-care">We Care</a></li>
                </ul>
            </nav>
        </div>
    </header>
    );
  }
}

export default Header;
