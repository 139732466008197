
import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);

    // Initialize state if needed
    this.state = {
      message: 'Hello, React!'
    };
  }

  render() {
    return (
        <footer>

        <div className="footer-container">
            <div className="footer-logo"><a href="https://myjeeva.net/"><img src="images/footer-logo.svg"/></a></div>

            <div className="footer-menu">
                <div>
                    <div className="footer-menu-title">
                        Company
                    </div>
                    <a href="https://myjeeva.net/#welcome">About</a>
                    <a href="https://myjeeva.net/#we-care">We Care</a>
                    <a href="https://myjeeva.net/#download">Products</a>
                    <a href="https://myjeeva.net/resources/">Resources</a>
                </div>

                <div>
                    <div className="footer-menu-title">
                        Other Links
                    </div>
                    <a href="https://myjeeva.net/privacy-policy/">Privacy</a>
                    <a href="https://myjeeva.net/terms-of-use/">Terms of Use</a>
                    <a href="https://myjeeva.net/disclaimer/">Legal Disclaimer</a>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="footer-container">
                © 2023 MyJEEVA Private Limited | Crafted by <a href="https://oneandonlydesign.in/">OneAndOnlyDesign</a> (Subsidiary of DesignCookie, USA)
            </div>
        </div>
    </footer>
    );
  }
}

export default Footer;
