
import React, { Component } from 'react';

class AdminUiRedirect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onBoardingStatus: false
    };
  }

  componentDidMount() {
    const { state } = this.props.location;
    if (state && state.onBoardingStatus !== undefined) {
      const { onBoardingStatus } = state;
      this.setState({ onBoardingStatus });
    }

  }

  render() {

    const redirectUrl =
      window.location.href === "https://dev-onboarding.myjeeva.net/nuo/redirect/admin-ui"
        ? "https://dev-admin.myjeeva.net/my-jeeva/login"
        : "https://admin.myjeeva.net/my-jeeva/login";

    return (
      <div className='container'>
        <div className='form-container' style={{
          display: "flex",
          flexDirection: "column", alignItems: "center", gap: '10px'
        }}>
          {this.state.onBoardingStatus ?
            <div>
              <h2 style={{ color: "#FA3199", fontSize: "20px" }}>Hi {this.props.location.state.userName},</h2>
              <br />
              <h3 style={{ color: "#FA3199", lineHeight: "1.5rem", marginTop: "-20px", fontSize: "15px" }}>
                Registration has already been completed for {this.props.location.state.userEmail}.
                Explore our user-friendly interface by clicking the button below to effortlessly add service lines and hospitals,
                streamlining your billing process for a seamless experience.
              </h3>
            </div>
            :
            <h3 style={{ color: "#FA3199", lineHeight: "1.5rem", fontSize: "15px" }}>
              Congratulations! Your registration has been successfully submitted.
              Thank you for joining us! Explore our user-friendly interface with by clicking below button to effortlessly add service lines and hospitals,
              streamlining your billing process for a seamless experience.
            </h3>
          }

          <a className='form-btn' style={{ textDecoration: "none" }}
            href={redirectUrl}

            target="_blank" rel="noopener noreferrer">
            <button style={{ letterSpacing: "1.2px" }}>Go to Admin</button>
          </a>
        </div>

      </div>
    );
  }
}

export default AdminUiRedirect;
